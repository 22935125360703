class HomeSlider extends Component {
	constructor(el) {
		super(el);
		this.swiperEl = this.$find('[data-swiper]');
		this.swiper = null;
		this.items = this.$findAll('[data-component="HomeSliderItem"]');
		this.swiperEl && this.constructSwiper();
	}

	constructSwiper() {
		this.swiper = new Swiper(this.swiperEl, {
			autoplay: {
				delay: 4000,
			},
			loop: (this.items.length > 1) ? true : false,
			pagination: {
				el: '[data-home-pagination]',
				clickable: true,
				type: 'fraction',
			},
			navigation: {
				nextEl: '[data-home-next]',
				prevEl: '[data-home-prev]',
			},
		});
	}
}
